import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';



import { useState, useEffect } from 'react';
import moment from 'moment';

import { useMediaQuery } from 'react-responsive';
import { getRowIdFromRowModel } from '@mui/x-data-grid/internals';

import { updateStatus, getEvents, setPeriod, checkPeriod, updateServerRow, getAllRates } from '../../network/event';

function EventManagment(props) {
    const [ rows, setRows ] = useState({})

    const columns = [
        { field: 'reference', headerName: 'Reference Number', flex: 0.112 },
        { field: 'name', headerName: 'Name', flex: 0.1 },
        { field: 'sw', headerName: 'Season', editable: true, type: 'singleSelect', valueOptions: ['Winter', 'Summer'], flex: 0.06 },
        { field: 'type', headerName: 'Type', type: 'singleSelect', valueOptions: ['Mid-Week', 'Weekend'], flex: 0.071 },
        { field: 'startdate', headerName: 'Check In', flex: 0.09, editable: true, type: 'date' },
        { field: 'enddate', headerName: 'Check Out', flex: 0.09, editable: true, type: 'date' },
        { field: 'rooms', headerName: 'Rooms', type: 'number', flex: 0.13 },
        { field: 'beds', headerName: 'People', type: 'number', flex: 0.01, editable: true },
        { field: 'freeNights', headerName: 'Free Nights', type: 'number', flex: 0.07 },
        { field: 'nights', headerName: 'Nights', flex: 0.01 },
        { field: 'status', headerName: 'Status', editable: true, type: 'singleSelect', valueOptions: ['Pending', 'Confirmed Unpaid', 'Confirmed Paid', 'Cancelled'], flex: 0.055 }, //valueOptions: ['Pending', 'Confirmed Unpaid', 'Confirmed Paid', 'Set for deletion']
        { field: 'payment', headerName: 'Payment',editable: true, type: 'boolean', flex: 0.05 },
        { field: 'estimatedCost', headerName: 'Estimated Cost With Tax', flex: 0.07 },
        { field: 'adjustCost', headerName: 'Adjust Cost', type: 'number', editable: true, flex: 0.07 },
        { field: 'finalCost', headerName: 'Final Cost With Tax', type: 'number', flex: 0.07 },
        { field: 'notes', headerName: 'Notes', editable: true, flex: 0.3 },
    ]

    const [selected, setSelected] = useState(0);
    const [trows, setTrows] = useState([]);
    const [loading, setLoading] = useState('block')

    function calculateEventDuration(startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const duration = Math.abs(end - start) / (1000 * 60 * 60 * 24);
        return duration;
    }
    
    function isOnWeekend(startDate, endDate) {
        let day1 = new Date(startDate)
        let day2 = new Date(endDate)
        if (day1.getDay() === 5 && day2.getDay() === 6 && calculateEventDuration(startDate, endDate) === 1) {
            return true;
        } else {
            return false;
        }
    }
    
    function isAtWinter(startDate, endDate) {
        let month = new Date(startDate).getMonth();
        if (month === 12 || month <= 2) {
            return "Summer";
        } else if (6 <= month <= 8) {
            return "Winter";
        } else if (3 <= month <=5) {
            return "Autumn";
        } else {
            return "Spring";
        }
    }

    
    function convertToMelbourneTime(utcDateString) {
        // Create a Date object from the UTC date string
        const utcDate = new Date(utcDateString);
        
        // Define options for formatting the date
        const options = {
            timeZone: 'Australia/Melbourne', // Set the time zone to Melbourne
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
        
        // Convert the UTC date to Melbourne time and format it
        const melbourneDate = utcDate//.toLocaleString('en-AU', options);
        console.warn(melbourneDate)
        return melbourneDate;
    }
    
    const calculateNights = (startDate, endDate) => {
        const start = moment(startDate);
        console.log(startDate)
        const end = moment(endDate);
        return end.diff(start, 'days');
    };

    function getNightsArray(startDate, endDate) {
        let currentDate = moment(startDate);
        const end = moment(endDate);
        const nightsArray = [];
    
        while (currentDate < end) {
            nightsArray.push({
                dayOfWeek: currentDate.format('d'), // 'd' returns day of the week from 0 (Sunday) to 6 (Saturday)
                fullDate: currentDate.format('YYYY-MM-DD')
            });
            currentDate.add(1, 'day');
        }
    
        return nightsArray;
    }

    function calculateCostFullb(e, rates) {
        let trueStart = e.extendedProperties.private.trueStart;
        let trueEnd = e.extendedProperties.private.trueEnd;
        let nights = getNightsArray(trueStart, trueEnd);
        let season = e.extendedProperties.private.sw;
        let people = parseInt(e.extendedProperties.private.beds);
        let freeNights = parseInt(e.extendedProperties.private.freeNights);

        let r = rates[`${e.extendedProperties.private.sw};${trueStart.substring(0, 4)}`];

        let totalcost = 0;

        // get amount of weekdays and weekends
        let weekendDays = 0;
        let weekdays = 0;
        for (let i = 0; i<nights.length; i++) {
            let n = nights[i];
            if (`${n.dayOfWeek}` === `5` || `${n.dayOfWeek}` === `6`) {
                weekendDays++;
            } else {
                weekdays++;
            }
        }
        console.log(`weekendDays = ${weekendDays}`);
        console.log(`weekdays = ${weekdays}`);

        // order by most expensive

        // go through array of nights and add
        for (let i = 0; i<nights.length; i++) {
            let n = nights[i];
            nights[i].cost = 0;
            if (`${n.dayOfWeek}` === `5` || `${n.dayOfWeek}` === `6`) {
                console.log('weekend' + n.dayOfWeek)
                // weekend day so use weekend rate
                totalcost += r.weekendRate;
                if (people > r.personLimit) {
                    // use extra people
                    totalcost += (people - r.personLimit) * r.extraPersonFee;
                }
                nights[i].cost += r.weekendRate;
                if (people > r.personLimit) {
                    // use extra people
                    nights[i].cost += (people - r.personLimit) * r.extraPersonFee;
                }
            } else {
                console.warn(n.dayOfWeek)

                //console.log('not weekend')
                try {
                totalcost += r.midWeekRate;
                if (people > r.personLimit) {
                    // use extra people
                    totalcost += (people - r.personLimit) * r.extraPersonFee;
                }
                nights[i].cost += r.midWeekRate;
                if (people > r.personLimit) {
                    // use extra people
                    nights[i].cost += (people - r.personLimit) * r.extraPersonFee;
                }
            } catch {}
            }
        }

        console.log(nights)

        // order nights by cost
        let ordered = [];
        let most = 0;
        for (let i = 0; i<nights.length; i++) {
            if (nights[i].cost >= most) {
                most = nights[i].cost;
                ordered.unshift(nights[i]);
            } else {
                ordered.push(nights[i])
            }
        }
        console.warn(ordered)
        //freeNights = 0;
        // get first 2 most expensive
        for (let i = 0; i<freeNights; i++) {
            console.warn(ordered[i])
            totalcost = totalcost - ordered[i].cost;
        }

        totalcost = totalcost * (1 + (r.tax / 100))

        return (totalcost > 0) ? totalcost : 0;
    }

    function calculateCostFulla(e, rates) {
        let trueStart = e.extendedProperties.private.trueStart;
        let trueEnd = e.extendedProperties.private.trueEnd;
        console.log(getNightsArray(trueStart, trueEnd));
        let totalCost = 0;
        let dateOne = new Date(trueStart.substring(0, 10));
        console.log(dateOne)
        let r = rates[`${e.extendedProperties.private.sw};${trueStart.substring(0, 4)}`]
        let nights = calculateNights(e.start.dateTime.substring(0, 10), e.end.dateTime.substring(0, 10));
        let isWeekend = false;
        //let dateOne = new Date(e.start.dateTime.substring(0, 10));
        //let dateTwo = new Date(e.end.dateTime.substring(0, 10));
        console.log(trueStart)
        //let dateOne = new Date(trueStart.substring(0, 10));
        let dateTwo = new Date(trueEnd.substring(0, 10));
        console.warn(dateOne.getDay())
        if (nights <= 2 && dateOne.getDay() === 5) {
            isWeekend = true;
        } else if (nights <= 2 && dateOne.getDay === 6) {
            isWeekend = true;
        }
        let isWinter = true;
        if (e.extendedProperties.private.sw === "Summer") {
            isWinter = false;
        }
        let people = parseInt(e.extendedProperties.private.beds);
        let personLimit = r.personLimit;
        if (isWeekend) {
            totalCost += r.weekendRate * nights;
            if (people > personLimit) {
                totalCost += r.extraPersonFee * nights * (people - personLimit);
            }
            return totalCost
        } else {
            totalCost += r.midWeekRate * nights;
            if (people > personLimit) {
                totalCost += r.extraPersonFee * nights * (people - personLimit)
            }
            return totalCost
        }
    
        // if (isWeekend) {
        //     totalCost += r.weekendRate * nights;
        //     if (people > personLimit) {
        //         totalCost += r.extraPersonFee * (people - personLimit) * nights
        //     }
        // } else {
        //     if (nights < 5 && (dateOne.getDay() === 7 || dateOne.getDay() >= 0) && dateTwo.getDay() <= 5) {
        //         totalCost += nights * r.midWeek;
        //         if (people > personLimit) {
        //             totalCost += r.extraPersonFee * (people - personLimit) * nights
        //         }
        //     }
        // }
        if (totalCost > 0) {
            return totalCost;
        } else {
            return "Error";
        }
    }

    function addRows(array, rates) {
        let newObj = []
        for(let i=0;i<array.length;i++) {
            console.log(i)
            if (array[i].summary === undefined) {
                array[i].summary = ''
            }
            let type = array[i].extendedProperties.private.type
            if (type === "1") {
                type = 'Weekend'
            } else if (type === "2") {
                type = 'Mid-Week'
            } else {
                type = 'Full Week'
            }
            let room;
            if (`${array[i].extendedProperties.private.rooms}` === `1`) {
                room = "Middle(5 Beds)";
            } else {
                room = "Corner(4 Beds)";
            }
            let sw = array[i].extendedProperties.private.sw
            // if (sw === 1) {
            //     sw = 'Summer'
            // } else {
            //     sw = 'Winter'
            // }
            if (array[i].summary === 'deleted') {
            } else {
            newObj.push({ 
                id: array[i].id,
                reference: array[i].extendedProperties.private.referenceNumber,
                status: array[i].extendedProperties.private.status,
                memberId: array[i].extendedProperties.private.memberId, 
                // startdate: array[i].start.dateTime.substring(0, 10), 
                // enddate: array[i].end.dateTime.substring(0, 10),
                // startdate: array[i].extendedProperties.private.trueStart.substring(0, 10), 
                // enddate: array[i].extendedProperties.private.trueEnd.substring(0, 10),
                startdate: convertToMelbourneTime(array[i].extendedProperties.private.trueStart),
                enddate: convertToMelbourneTime(array[i].extendedProperties.private.trueEnd),
                nights: calculateNights(array[i].extendedProperties.private.trueStart.substring(0, 10), array[i].extendedProperties.private.trueEnd.substring(0, 10)),
                name: array[i].extendedProperties.private.memberName, 
                memberId: array[i].extendedProperties.private.memberId, 
                freeNights: parseInt(array[i].extendedProperties.private.freeNights),
                beds: parseInt(array[i].extendedProperties.private.beds), 
                rooms: room,
                type: type,
                sw: sw,
                notes: array[i].extendedProperties.private.notes,
                payment: JSON.parse(array[i].extendedProperties.private.payment),
                estimatedCost: calculateCostFullb(array[i], rates),
                adjustCost: array[i].extendedProperties.private.adjustCost,
                finalCost: calculateCostFullb(array[i], rates) + parseInt(array[i].extendedProperties.private.adjustCost),
                })
            }
            //console.log(newObj[i])
        }
        console.log(newObj)
        setRows(newObj)
        //setLoading('none')
    }

    const [finalRows, setFinalRows] = useState({})

    function saveRowsToServer() {
        let data = { events: [] }
        let keys = Object.keys(finalRows)
        for (let i = 0;i<keys.length;i++) {
            console.log(finalRows[keys[i]])
            let objType = 1;
            if (finalRows[keys[i]].type === 'Weekend') {
                objType = 1
            } else {
                objType = 2
            }
            let obj = {
                status: finalRows[keys[i]].status,
                beds: `${finalRows[keys[i]].beds}`,
                rooms: finalRows[keys[i]].rooms,
                sw: finalRows[keys[i]].sw,
                type: `${objType}`,
                notes: finalRows[keys[i]].notes,
                payment: finalRows[keys[i]].payment,
                eventId: finalRows[keys[i]].id,
                memberId: finalRows[keys[i]].memberId,
                referenceNumber: finalRows[keys[i]].reference,
                startDate: finalRows[keys[i]].startdate,
                endDate: finalRows[keys[i]].enddate,
                adjust: `${finalRows[keys[i]].adjustCost}`,
                estimatedCost: finalRows[keys[i]].estimatedCost
            };
            data.events.push(obj)
        } 
        console.log(data)
        props.startLoading()
        updateServerRow('', data)
        .then(r => {
            console.log(r)
            setRows({id:'123'})
            getAllRates()
            .then(r => {
                r = JSON.parse(r)
                let ratesObj = {}
                for (let i = 0; i<r.length; i++) {
                    ratesObj[r[i].yearsw] = r[i]
                }
                console.warn(ratesObj)
                getEvents()
                .then(r => {
                    addRows(r, ratesObj)
                    props.stopLoading()
                })
            })
        })

    }
    

    useEffect(() => {
        checkPeriod()
        .then(r => {
            console.log(r)
            setBookingPeriodStatus(`${r[0].period} ${r[0].type}`)
        })
        getAllRates()
        .then(r => {
            r = JSON.parse(r)
            let ratesObj = {}
            for (let i = 0; i<r.length; i++) {
                ratesObj[r[i].yearsw] = r[i]
            }
            console.warn(ratesObj)
            getEvents()
            .then(r => {
                addRows(r, ratesObj)
                setLoading('none')
            })
        })
    }, [])
    const [bookingPeriodStatus, setBookingPeriodStatus] = useState('Loading booking period info...')

    return (<>
        <div className='datagridcontainer'>
            <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
            pagination: {
                paginationModel: { page: 0, pageSize: 5 },
            },
            }}
            pageSizeOptions={[5, 10]}
            // checkboxSelection
            // getRowId={(row) => ({id:generateRandom})}
            sx={{
                backgroundColor: "none"
            }}
            processRowUpdate={(updatedRow, originalRow) => {
                setFinalRows(prevRows => {
                    const newRows = { ...prevRows };
                    newRows[updatedRow.id] = updatedRow;
                    return newRows;
                });
                return updatedRow;
            }}
            onProcessRowUpdateError={() => {}}
            onRowSelectionModelChange={(newRowSelectionModel) => {
                setTrows(newRowSelectionModel);
                console.log(newRowSelectionModel)
            }}
            rowSelectionModel={trows}
        />
        </div>
        <div className='userbuttons flex'>
        <Button sx={{width: "50%", marginLeft: "10px", marginRight: "10px"}} variant="contained" onClick={saveRowsToServer}>Save Changes Made</Button>
        {/* <Button onClick={() => {
            props.startLoading()
            setRows({id:'123'})
            getAllRates()
            .then(r => {
                r = JSON.parse(r)
                let ratesObj = {}
                for (let i = 0; i<r.length; i++) {
                    ratesObj[r[i].yearsw] = r[i]
                }
                console.warn(ratesObj)
                getEvents()
                .then(r => {
                    addRows(r, ratesObj)
                    props.stopLoading()
                })
            })
        }}>test</Button> */}
        </div>
        </>)
}

export default EventManagment;