import { useEffect, useState } from "react";

import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";

import { getRates, getAllRates, setRates, createNextRate } from "../../network/event";

import { DataGrid } from '@mui/x-data-grid';


function RatesTable(props) {
    useEffect(() => {
        getAllRates()
        .then(rates => {
            setRatesRows(rates)
        })
    }, [])

    const columns = [
        { field: 'year', headerName: 'Year' },
        { field: 'sw', headerName: 'Season' },
        { field: 'midWeekRate', headerName: 'Mid-Week Rate', width: 150, type: 'number', editable: true },
        { field: 'weekendRate', headerName: 'Weekend Rate', type: 'number', editable: true },
        { field: 'personLimit', headerName: 'Person Limit', type: 'number', editable: true },
        { field: 'extraPersonFee', headerName: 'Extra Person Fee', width: 90, type: 'number', editable: true },
        { field: 'tax', headerName: 'Tax Percent', width: 90, type: 'number', editable: true }
    ]

    const [rows, setRows] = useState([
        {
            id: '1'
        }
    ]);

    function setRatesRows() {
        let newRows = []
        getAllRates()
        .then(r => {
            let rates = JSON.parse(r)
            console.log(rates)
            for (let i = 0;i<rates.length;i++) {
                let rate = {
                    extraPersonFee: rates[i].extraPersonFee,
                    midWeekRate: rates[i].midWeekRate,
                    weekendRate: rates[i].weekendRate,
                    year: rates[i].yearsw.split(';')[1],
                    sw: rates[i].yearsw.split(';')[0],
                    personLimit: rates[i].personLimit,
                    id: rates[i].yearsw,
                    tax: rates[i].tax
                }
                newRows.push(rate)
            }
            console.log(newRows)
            setRows(newRows)
        })
    }

    const [finalRows, setFinalRows] = useState([])

    return (<>
        <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
            pagination: {
                paginationModel: {
                pageSize: 5,
                },
            },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
            processRowUpdate={(updatedRow, originalRow) => {
                setFinalRows(prevRows => {
                    const newRows = { ...prevRows };
                    newRows[updatedRow.id] = updatedRow;
                    return newRows;
                });
                return updatedRow;
            }}
            onProcessRowUpdateError={() => {}}
        />
        <Button onClick={() => {
            props.startLoading()
            setRates(finalRows)
            .then(r => {
                setRows({id:'123'})
                getAllRates()
                .then(rates => {
                    setRatesRows(rates)
                    props.stopLoading()
                })
            })
        }} variant="contained">Save Changes</Button>
    </>)
}

export default RatesTable;