import './login.css';
import logo from '../pages/logo.png';
import { RetrieveToken, readCookie, setCookie } from '../network/user.js';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { useState, useEffect } from 'react';

function Login(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    function start() {
        RetrieveToken(email, password)
        .then(r => {
            if (r === false) {
                setAlert({
                    visible: 'block',
                    text: 'Incorrect Credentials',
                    type: 'error',
                    title: 'Error'
                })
            } else {
                setCookie('token', r, 999999)
                setAlert({
                    visible: 'block',
                    text: 'Success',
                    type: 'success',
                    title: 'Success'
                })
                window.location.href = '/';
            }
        })
    }

    const [error, setError] = useState('none');

    const [alert, setAlert] = useState({
        visible: 'none',
        text: 'Incorrect Credentials',
        type: 'error',
        title: 'Error'
    })  
    
    return (<>
        <div className='mobile-signin'>
        <img className='logo-img-mobile' src={logo} alt='logo'></img>
            <span className='signin-heading'>Sign in</span>
            Enter Credentials
            <TextField value={email} onChange={(event) => {
                setEmail(event.target.value)
            }} id="outlined-basic" label="Email Address" variant="outlined" />
            <TextField value={password} onChange={(event) => {
                setPassword(event.target.value)
            }} id="outlined-basic" label="Password" type='password' variant="outlined" />
            <Button onClick={start} sx={{width: '55%'}} variant='contained'>Sign in</Button>
            <Alert sx={{
                marginTop: "30px",
                marginBottom: "30px",
                width: "50%",
                paddingRight: 0,
                marginLeft: "0%",
                display: alert.visible
            }} severity={alert.type}>
                <div className='links1'>
                    
                </div>
                <AlertTitle>{alert.title}</AlertTitle>
                {alert.text}
                <br></br>
                <a href='/reset-password' className='' style={{fontSize: '14px', width: '100%', margin: 0, textAlign:'center'}}>Forgot Password?</a>
            </Alert>
        </div>
    </>)
}

export default Login;