import HomeMenu from "./shared/homeMenu";
import './home.css';

import React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useState } from "react";
import { useNavigate, Link } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { setCookie, readCookie, checkAdminRequest } from "../network/user";
import { useEffect } from "react";

function Home(props) {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);

    useEffect(() => {
        checkAdminRequest()
        .then(r => {
            console.log(r)
            if (r.admin) {
                setAdmin(<BottomNavigationAction onClick={() => {
                    navigate('/admin');
                }} label="Admin" icon={<CalendarMonthIcon />} />)
            }
        })
        if (readCookie("token") == null || readCookie("token") == "deleted") {
            window.location.href = '/login';
        }
        setInterval(() => {
            if (readCookie("token") == null || readCookie("token") == "deleted") {
                window.location.href = '/login';
            }
        }, 4000)
    }, [])
    //setCookie("token", "q10cq0vhrht", 1)

    const [admin, setAdmin] = useState(<></>)
    

    return (<div className="mobile-body">
        <div className="mobile-header">
            <span className="mobile-title">LAMBRUK</span>
            <span onClick={()=>{setCookie('token', '', 0);window.location.href = '/login';}} className="mobile-logout">Log out</span>
        </div>
        <div className="mobile-content">
            <h3 style={{marginLeft:"2vw"}}>Home</h3>
            <Accordion>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                General
            </AccordionSummary>
            <div className="text-area">
            <b>Introduction</b>
            <br></br>
            The Annual General Meeting is held in May of each year. Four Directors Meetings are held annually with a break during July & August because of the ski season, although Directors Meetings can be called at short notice to deal with urgent business.
            </div>
            <div className="text-area">
            <b>About the Accommodation</b>
            <br></br>
            The facility that Lambruk has shares in is a two-bedroom flat in the rear building of Les Chalets Apartments - Slalom Street, Falls Creek. All alpine property at Falls Creek is leasehold. Lambruk’s Lease was renewed in 2009. The Management body for both Les Chalets buildings is Site 210 which has its own Board of Directors and usually holds its Annual General Meeting at Falls Creek soon after the close of the ski season each year.<br></br><br></br>
            The flat is situated in a prime ski-in/ski-out location off the home trail of ‘Wombats Ramble’ with easy skiing access to the Falls Express Quad Chair. Owners and guests staying at Les Chalets do not have to rely on over-snow vehicle transportation to the accommodation. There is short-term parking located near St Falls for off-loading your luggage. After off-loading your luggage, you will be directed by a Parking Attendant where to park your vehicle in a long-term carpark. Apart from the excellent location of Les Chalets on the Mountain with its ski-in/ski-out proximity to Falls Express Quad Chair and long-term parking at Lower Slalom Carpark, current owners & guests are fortunate enough to be almost on the doorstep of “St Falls”. This area boasts retail shops, apartments, ticketing, rentals, restaurants, supermarket.<br></br><br></br>
            The flat is large with an open space lounge & kitchen area, the views from the flat are spectacular through to the Kiewa Valley and surrounding mountains. There is one bathroom and 2 toilets, a drying-cupboard in the foyer of the flat for ski boots and clothing, and Lambruk also has its own ski locker located in the foyer of the building. The bedding configuration in each bedroom consists of a double bed & set of single bunks, Best bring your own linen for simplicity but if you need there is linen in the flat but this must be laundered and returned to storage before you leave.<br></br>
             </div>
             <div className="text-area">
            <b>Lambruk’s Financials</b>
            <br></br>
            The Club’s Financials are audited annually and a copy lodged with the Dept of Justice. Current Income is derived from an Annual Members Levy & Bookings. Some minor income is gained from those Members not fulfilling Work Party requirements in the form of a Work Party Levy.<br></br>
            Annual running costs include Quarterly Payments to Site 210, TXU, Elgas, Auditors fees, Flat supplies/postage/photocopying. Apart from Lambruk’s internal annual running costs, Site 210 Management can also make a decision for capital works to be carried out, which Lambruk contributes to as a Shareholder in that company.
            </div>
            <div className="text-area">
            <b>Special Levies</b>
            <br></br>
            An Annual & Work Party Levies (if you do not participate in a work party once during a 3-year period) are currently in place. The Annual Levy to cover Lambruk’s annual fixed outgoings.
            </div>
            <div className="text-area">
            <b>Members Obligations</b>
            <br></br>
            Members are required to commit to a Work Party once within a 3-year period or pay a Work Party Levy. The 3-year period in which a Member may fulfil work party obligations is very generous & was established to accommodate those Members who may be living interstate or overseas for an extended period.            </div>
            <div className="text-area">
            <b>New Members</b>
            <br></br>
            Lambruk welcomes new Members, but does encourage all its members to be active and involved in the running of the Ski Club. Under the Club’s Rules, minimum active Membership does require a Member to be fully financial and complete a work party every 3-years. Prospective new members are required to meet with Lambruk’s Directors prior to any formal transfer of a Membership. Transfers are processed through the Board with a Share Certificate being issued on the date of the Transfer. Anyone interested in Membership should contact John Winter at john@jnwinter.com.au            </div>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                Flat Etiquette
            </AccordionSummary>
            <div className="text-area">
            <b>Flat Etiquette – 2020</b>
            <br></br>
            In a shared space such as Lambruk’s Flat, there are bound to be some issues that come out of the shared usage that Members pass onto Directors as of being a concern to them. It is also unpredictable from Season to Season what those issues may be, but there are some that come up on a regular basis. Directors have undertaken to put a few of these issues out there in the hope that Members & Guests will be respectful of those they are sharing with. Members should ensure their Guests are aware of these issues if they are not using the flat at the same time. This is not meant to represent yet another set of Flat Rules. Hopefully, it will be received in the spirit it was meant & that’s to facilitate a balance for all users – whether Members or Guests.
            </div>
            <div className="text-area">
            <b>Etiquette Guidelines – 2020</b>
            <br></br>
            <li>Outdoor Footwear should not usually be worn beyond the Foyer area of our Flat.</li>
            <li>Wet (smelly) Footwear should be stored in the Drying Cupboard, not in the Flat Foyer area.</li>
            <li>Snowboarding Boots to be put on in Flat Foyer or downstairs Locker – not in lounge or bedrooms as Ski Boots are often muddy.</li>
            <li>Mobiles – turned off or silent mode before going to bed please.</li>
            <li>Noise – please keep it down after 11pm. Others may already be in bed for an early rise.</li>
            <li>TV – as a courtesy, ask other flat users before dominating it, or turning it off – not all footy or cooking shows.</li>
            <li>Music – a balance again – shared tastes, or use headphones as much as possible.</li>
            <li>Food/Drink – preferably not in bedrooms. Use small tables in lounge, not couches or carpet (red wine stains!)</li>

            </div>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                Cleaning & Vacating
            </AccordionSummary>
            <div className="text-area">
            <b>Cleaning & Vacating Information</b>
            <br></br>
            The information below serves as a guide on how to take care of Lambruk's Flat and ensure it's left in a clean and usable state for the next members or guests. Adherence to these practices allows us to maintain a pleasant and hygienic living environment.
            </div>
            <div className="text-area">
            <b>Power & Heating – when vacating</b>
            <br></br>
            In the Ski Season: leave power on but adjust thermostat in lounge to 12deg.
            <br/>
            Outside the Ski Season: turn all fuse switches off – i.e. down.
            <br/>
            Boiler – Contact Greg Stroud (the Plumber) Mobile: 0409 002940 if there are any problems with the Boiler.            </div>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                Useful Links
            </AccordionSummary>
            <div className="text-area">
            <b>Links</b>
            <br></br>
            <br></br>
            <a href='http://www.fallscreek.com.au/' class='vbutton'>http://www.fallscreek.com.au/</a>
            <br></br>
            <br></br>

            <a href='http://www.vicroads.vic.gov.au/Home/' class='vbutton'>http://www.vicroads.vic.gov.au/Home/</a>
            <br></br>
            <br></br>

            <a href='http://www.greatalpinevalleys.com.au/pages/alpine-national-park-camping-hiking/' class='vbutton'>http://www.greatalpinevalleys.com.au/pages/alpine-national-park-camping-hiking/</a>
            <br></br>
            <br></br>

            <a href='http://www.hothambus.com.au/snow-bus_timetables_melbourne_snow.html' class='vbutton'>http://www.hothambus.com.au/snow-bus_timetables_melbourne_snow.html</a>
            <br></br>
            <br></br>

            </div>
            </Accordion>
        </div>
        <div className="mobile-nav">
            <Box sx={{ width: "100%", position:"absolute", bottom: "0dvh" }}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                    setValue(newValue);
                    }}
                >
                    <BottomNavigationAction onClick={() => {
                        navigate('/');
                    }} label="Home" icon={<HomeIcon />} />
                    <BottomNavigationAction onClick={() => {
                        navigate('/Book');
                    }} label="Book" icon={<AddBoxIcon />} />
                    <BottomNavigationAction onClick={() => {
                        window.location.href='https://calendar.google.com/calendar/u/0/embed?src=4b91b1fa220f3088776462033c6e91cd823736f23750187a1a5def065747024a@group.calendar.google.com&ctz=Australia/Melbourne'
                    }} label="Calendar" icon={<CalendarMonthIcon />} />
                    {admin}
                </BottomNavigation>
            </Box>
        </div>
    </div>)
}

export default Home;